import { createGlobalStyle, css } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(far, fas)

const style = css`
  @import url('https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');

  html {
    font-family: 'Poppins', sans-serif;
  }
`

const Global = createGlobalStyle`
  ${style}
`

export default Global
