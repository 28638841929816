import { css } from 'styled-components'

export const colors = {
  dark: '#242424',
  lightGray: '#DEDBDB',
  gray: '#706F6B',
  background: '#F8F8F8',
  main: '#E0BD6A',
  mainOpaque: 'rgba(224, 189, 106, 0.2)',
  white: '#FFFFFF',
  borders: '#BFBCBC',
  copyright: '#181818',
  copyrightSecondary: '#565552',
}

export const fontWeight = {
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
}

export const fontStylesMap = {
  links: css`
    font-weight: ${fontWeight.medium};
    font-size: 15px;
    color: ${colors.gray};
  `,
  headingOne: css`
    font-weight: ${fontWeight.bold};
    font-size: 56px;
    color: ${colors.dark};
    line-height: 1.25;
  `,
  headingOneColor: css`
    font-weight: ${fontWeight.bold};
    font-size: 56px;
    color: ${colors.main};
    line-height: 1.25;
  `,
  headingOneLight: css`
    font-weight: ${fontWeight.light};
    font-size: 56px;
    color: ${colors.dark};
    line-height: 1.25;
  `,
  headingOneExtraLight: css`
    font-weight: ${fontWeight.extraLight};
    font-size: 56px;
    color: ${colors.white};
    line-height: 1.25;
  `,
  headingTwo: css`
    font-weight: ${fontWeight.light};
    font-size: 39px;
    color: ${colors.dark};
    line-height: 1.3;
  `,
  headingTwoWhite: css`
    font-weight: ${fontWeight.light};
    font-size: 39px;
    color: ${colors.white};
    line-height: 1.3;
  `,
  headingTwoBold: css`
    font-weight: ${fontWeight.bold};
    font-size: 39px;
    color: ${colors.dark};
    line-height: 1.3;
  `,
  headingTwoWhiteBold: css`
    font-weight: ${fontWeight.bold};
    font-size: 39px;
    color: ${colors.white};
    line-height: 1.3;
  `,
  textDark: css`
    font-weight: ${fontWeight.regular};
    font-size: 20px;
    color: ${colors.dark};
    line-height: 1.5;
  `,
  textGray: css`
    font-weight: ${fontWeight.regular};
    font-size: 20px;
    color: ${colors.gray};
    line-height: 1.7;
  `,
  buttonText: css`
    font-weight: ${fontWeight.semiBold};
    font-size: 15px;
    color: ${colors.background};
  `,
  buttonTextBig: css`
    font-weight: ${fontWeight.semiBold};
    font-size: 18px;
    color: ${colors.background};
  `,
  textLink: css`
    font-weight: ${fontWeight.medium};
    font-size: 18px;
    color: ${colors.dark};
  `,
  textLinkSmall: css`
    text-decoration: underline;
    font-size: 14px;
    font-weight: ${fontWeight.bold};
    letter-spacing: 0.84px;
    text-transform: uppercase;
    color: ${colors.white};
  `,
  textLinkBoldDark: css`
    font-weight: ${fontWeight.bold};
    font-size: 14px;
    color: ${colors.dark};
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
  textLinkBoldColored: css`
    font-weight: ${fontWeight.bold};
    font-size: 14px;
    color: ${colors.main};
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
  cardHeading: css`
    font-weight: ${fontWeight.regular};
    font-size: 25px;
    color: ${colors.dark};
    line-height: 1.4;
  `,
  cardHeadingColor: css`
    font-weight: ${fontWeight.extraBold};
    font-size: 25px;
    color: ${colors.main};
    line-height: 1.4;
  `,
  cardText: css`
    font-weight: ${fontWeight.regular};
    font-size: 17px;
    color: ${colors.gray};
    line-height: 1.7;
  `,
  profileTextItalic: css`
    font-weight: ${fontWeight.light};
    font-size: 20px;
    color: ${colors.gray};
    line-height: 1.7;
    font-style: italic;
  `,
  profileTitle: css`
    font-weight: ${fontWeight.light};
    font-size: 20px;
    color: ${colors.gray};
    line-height: 1.7;
    font-style: italic;
  `,
  copyrightText: css`
    font-weight: ${fontWeight.regular};
    font-size: 15px;
    color: ${colors.copyrightSecondary};
    line-height: 2.26;
  `,
  question: css`
    font-weight: ${fontWeight.semiBold};
    font-size: 20px;
    color: ${colors.dark};
    line-height: 1.7;
  `,
  blogTitle: css`
    font-weight: ${fontWeight.regular};
    font-size: 25px;
    color: ${colors.white};
    line-height: 1.7;
  `,
}

export const device = {
  mobileS: `(max-width: 375px)`,
  mobileM: `(max-width: 425px)`,
  mobileL: `(max-width: 768px)`,
  tablet: `(max-width: 1024px)`,
  laptop: `(max-width: 1440px)`,
  laptopL: `(max-width: 2560px)`,
}
