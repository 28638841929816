import React, { ReactNode } from 'react'
import Global from '../../styles/global'
import Reset from '../../styles/reset'
import styled from 'styled-components'
import { colors } from '../../styles/variables'

const Main = styled.main`
  overflow-x: hidden;
  background-color: ${colors.background};
`

interface Props {
  children: ReactNode
}

const Layout = (props: Props) => {
  const { children } = props

  return (
    <>
      {/* TODO SEO */}
      <Reset />
      <Global />
      <Main>{children}</Main>
    </>
  )
}

export default Layout
